<template>
  <div class="px-2" id="page-dashboard">
    <!-- 6 instsances per row from "instances" array -->
    <div class="dashboard-container">
      <div class="instance-grid">
        <DashboardInstanceStatus
          v-for="(instance, index) in instances"
          :key="index"
          :instance="instance"
        />
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import DashboardInstanceStatus from '@/view/pages/ml/dashboard/DashboardInstanceStatus.vue';

import dayjs from 'dayjs';

export default {
  name: 'DashboardInstances',
  components: {
    DashboardInstanceStatus
  },
  data() {
    return {
      interval_id: null,
      instances: []
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods', 'isTHS']),
    num_rows() {
      return Math.ceil(this.instances.length / 6);
    }
  },
  mounted() {
    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.poll_instances();
    }, 5000);

    this.poll_instances();
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    }
  },
  methods: {
    async poll_instances() {
      try {
        const res = await axios.get(`/ths/instance`);

        if (res.status === 200) {
          this.instances = res.data;
        }
      }
      catch (err) {
        console.error('poll_instances error', err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.dashboard-container {
  padding: 0px;
}

.instance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

</style>
